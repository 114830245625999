<template>
  <section class="d-flex mt-0 py-4">
    <v-icon :left="!iconOnly">
      $ethereumIcon
    </v-icon>
    <section v-if="!iconOnly">
      <p class="body-1 font-weight-bold mb-0 text-capitalize">{{ currency }}</p>
      <p
        v-if="currencyLabel"
        class="subtitle-2 mt-1 text--secondary mb-0"
      >
        {{ currencyLabel }}
      </p>
    </section>
    <v-divider
      class="mx-3"
      vertical
    />
  </section>
</template>

<script>
export default {
  name: 'InputPrependedCurrency',
  props: {
    currency: {
      type: String,
      required: true,
      default: () => (''),
      validator: (val) => ['ETH'].includes(val.toUpperCase()),
    },
    iconOnly: {
      type: Boolean,
      required: false,
      default: () => (false),
    },
  },
  data: () => ({
    labels: {
      eth: 'Ethereum',
    },
  }),
  computed: {
    currencyLabel() {
      try {
        return this.labels[this.currency] ?? '';
      } catch {
        return '';
      }
    },
  },
};
</script>
